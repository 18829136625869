import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { LogoutUser, StoreState } from 'core/store';
import { SnackBarComponent } from '../../page/shared/components/snack-bar/snack-bar.component';

export const WhiteList = [
    'NamesNotFound',
    'AppointmentNotExists',
    'AppointmentUserTasksIsEmpty',
    'ClientIsExists',
];

@Injectable()
export class ErrorHandler {
    constructor(
        public snackbar: MatSnackBar,
        public translate: TranslateService,
        public store: Store<StoreState>,
    ) {}

    handleError(err: any) {
        if (err.status === 401) {
            this.store.dispatch(new LogoutUser());
        } else {
            try {
                if (err.error instanceof Blob) {
                    err.error.text().then(value => {
                        if (value) {
                            const isReport = err.url.includes('Reports');
                            const message = JSON.parse(value);
                            if (message && !isReport) {
                                if (message.ErrorText) {
                                    this.snackbar.open(message.ErrorText[0], 'ОК', {
                                        duration: 300000,
                                    });
                                } else if (message?.error?.message) {
                                    this.snackbar.open(message?.error?.message, 'ОК', {
                                        duration: 300000,
                                    });
                                }
                            }
                        }
                    });
                } else if (err.error?.ErrorCode && err.error?.ErrorText?.length) {
                    if (err.error?.ErrorCode[0] && !WhiteList.includes(err.error?.ErrorCode[0])) {
                        if (err?.error?.ErrorCode[0] !== 'ClientIsExists') {
                            this.snackbar.open(err.error?.ErrorText[0], 'ОК', {
                                duration: 300000,
                            });
                        }
                    }
                } else if (err.error?.ErrorCode && err.error?.Messages?.length) {
                    this.snackbar.openFromComponent(SnackBarComponent, {
                        data: err.error?.Messages.reduce((acc, v) => {
                            if (v) {
                                return acc?.length ? acc + '<br>' + v : v;
                            } else {
                                return v;
                            }
                        }, ''),
                        duration: 300000,
                    });
                } else if (err.error?.error?.message) {
                    this.snackbar.open(err.error?.error?.message, 'ОК', {
                        duration: 300000,
                    });
                } else if (err.error?.errorText) {
                    this.snackbar.open(err.error?.errorText[0], 'ОК', {
                        duration: 300000,
                    });
                } else if (err.error?.ErrorText) {
                    this.snackbar.open(err.error?.ErrorText[0], 'ОК', {
                        duration: 300000,
                    });
                }
                if (err.error?.Value) {
                    this.snackbar.open(err.error?.Value[0], 'ОК', {
                        duration: 300000,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //   return next.handle(req)
    //     .pipe(
    //       catchError(err => {
    //         if (err.status === 401) {
    //           this.store.dispatch(new LogoutUser());
    //         } else if (err.error?.Value) {
    //           this.snackbar.open(err.error?.Value[0], 'ОК', {
    //             duration: 5000
    //           });
    //         } else if (err.error?.ErrorCode) {
    //             if (err.error?.ErrorCode[0] && !WhiteList.includes(err.error?.ErrorCode[0])) {
    //               console.log('err.error?.ErrorCode[0]');
    //               this.snackbar.open(err.error?.ErrorText[0], 'ОК', {
    //                 duration: 5000
    //               });
    //             }
    //         } else {
    //           console.log('err.error?.ErrorText[0]');
    //           this.snackbar.open(err.error?.ErrorText[0], 'ОК', {
    //             duration: 5000
    //           });
    //         }
    //         return throwError(err);
    //       })
    //     );
    // }
}
